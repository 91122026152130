div#map {
  display: flex;
  flex: 1;
  width: 100%;
}

.popup-props-container {
  display: flex;
  flex-direction: column;
}
