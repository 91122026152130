.legend {
  background-color: #fff;
  border-radius: 3px;
  bottom: 2rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  position: absolute;
  left: 0.5rem;
  z-index: 1;
  opacity: 0.9;
  height: 110px;
  overflow-y: scroll;
  transition: 0.2s;
}
.legend:hover {
  height: 200px;
}

@media (max-width: 1073px) {
  .legend {
    font-size: 0.7rem;
  }
}

@media (max-height: 800px) {
  .legend {
    font-size: 0.7rem;
  }
}

.legend-header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 0.5rem;
}

.legend h4 {
  margin: 0;
  font-weight: bold;
  white-space: pre-wrap;
  padding-right: 0.5rem;
}

.legend div span.legend-element-style {
  border-radius: 50%;
  display: inline-block;
  height: 0.5rem;
  margin-right: 0.5rem;
  width: 0.5rem;
  pointer-events: none; /* To avoid bug when clicking on the span */
}

.legend-class-clickable {
  cursor: pointer;
}

.unselected {
  opacity: 0.3;
  text-decoration: line-through;
}

.legend-class-row {
  display: flex;
}

.legend-part-selection {
  margin-bottom: 0.3rem;
}

.legend-element-selection {
  background-color: #7aff33;
  border: solid 1px #0f0401;
}
