#loading {
  position: absolute;
  right: 50%;
  top: 40%; /* 40% = 15 (menu's height) + 25 (map-container's height / 2). Like that the spinner is displayed at the center of the map*/

  width: 50px;
  height: 50px;
  border: 3px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top-color: rgb(19, 17, 17);
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  z-index: 1;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
