/* !important is needed to overwrite: width: 520px; */
.ant-modal {
  height: 85vh !important;
  width: 50vw !important;
  animation-duration: 0s !important; /*To see if it solves the issue with the chart that sometimes is very small */
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.ant-modal-content {
  height: 100%;
  width: 100%;
}

.ant-modal-header {
  height: 7%;
}

.ant-modal-body {
  height: 90%;
  padding: 0 !important;
}

.chart-level1 {
  height: 100%;
  width: 100%;
}

.metadata {
  padding: 4px;
  height: 10%;
  background-color: #1f3349;
  color: #ffffff;
}

.tab {
  overflow: hidden;
  background-color: #f1f1f1;
  height: 7%;
}

.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 1rem 1rem;
  font-size: 0.9rem;
  height: 100%;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  font-weight: bold;
  background-color: white;
}

.chart-level2 {
  height: 83%;
  width: 100%;
}

.container-nodata {
  text-align: center;
  margin-top: 2rem;
}
