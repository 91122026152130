.chart-container {
  height: 33%;
  /* border: 1px solid red; */
}

.chart-space {
  height: 12%;
}

.chart-title {
  text-align: center;
  font-weight: bold;
  height: 8%;
}
.chart-legend {
  height: 8%;
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
}

.chart-element {
  height: 72%;
}

.legend-element {
  display: flex;
  align-items: center;
  padding-left: 1.2rem;
}

.legend-element-color {
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

.legend-element-label {
  padding-left: 0.5rem;
}

div.tooltip {
  z-index: 1000;
  opacity: 0;
  background-color: white;
  border-width: 1px;
  border-radius: 4px;
  font-size: 0.8rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.tooltip-title {
  width: 100%;
  text-align: center;
  font-weight: bold;
  background-color: #1f3349;
  color: white;
  padding: 2px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.tooltip-body {
  padding: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
.tooltip-item {
  display: flex;
  align-items: center;
}
.tooltip-body > div:not(:last-of-type) {
  border-bottom: 1px solid #f4f7fb;
}
.tooltip-item-icon {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 0.8rem;
}

.tooltip-item-line {
  height: 100%;
  margin-right: 0.4rem;
}
.tooltip-item-label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
