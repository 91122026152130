.bt-link-ts {
  background: none;
  border: none;
  outline: none;
  padding: 0;
  color: #1890ff;
  cursor: pointer;
  text-align: left;
  width: 50%;
}
