#sixhour-title {
  text-align: center;
}

.btn-period {
  height: 2rem;
  width: 2rem;
  border: none;
  border-radius: 50%;
  color: white;
  padding: 0.25rem;
  margin: 0.25rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}
.btn-period:focus {
  outline: none;
}
.period-selected {
  color: #006d2c;
  font-weight: bold;
  border: 1.5px solid #006d2c;
}
.period-available {
  cursor: pointer;
  background-color: rgb(122, 204, 122);
}
.period-missing {
  cursor: not-allowed;
  color: gainsboro;
  background-color: rgb(238, 128, 88);
}
