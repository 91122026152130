#root {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: calc(100vh - 48px - 50px);
  box-sizing: border-box;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 62.5%;
}

@media all and (max-width: 954px) {
  #root {
    min-height: calc(100vh - 60px);
  }
}

/* Necessary to overwrite the style on 'h1, h2, h3, h4, h5, h6'
with 'color: rgba(0,0,0,.85);' (coming from antd?) so that the
title of the app is not black on black background, hence not visible
*/
.ecmwf-template__branding {
  color: #fff;
}
